import axios from "axios";
import { motion } from "framer-motion";
import React, { useState } from "react";

function ModalSolicitud({ selectedId, setSelectedId }) {
  const [data, setData] = useState({
    firstArtis: "",
    user: "",
    email: "",
    secondArtis: "",
  });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);

  async function completePoll() {
    setLoading(true);
    await axios
      .post("https://global-boletos-socket-io-server-2cmhx3gilq-uc.a.run.app/api/entrepreneur/createPoll", data)
      .then((res) => {
          setLoading(false);
          setData({
            firstArtis: "",
            user: "",
            email: "",
            secondArtis: "",
          })
        //alert("Gracias por culminar la encuesta");
        setAlert(true)
      });
  }

  return (
    <div>
      {alert && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 0.1 } }}
          className=" p-5 fixed z-[60] w-full h-screen  inset-0  m-auto bg-black/50 "
          onClick={() => {
            setAlert(false);
            setSelectedId(null);
          }}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.15 } }}
            transition={{ duration: 0.2, delay: 0.15 }}
            style={{ pointerEvents: "auto", backdropFilter: "blur(5px)" }}
            className=" ligthNeon shadow-lg hover:bg-white/25  neons text-white p-2 fixed z-[70] flex flex-col justify-between  items-center py-12 inset-x-0 top-5  neon     w-full lg:w-[500px] min-h-[200px]     m-auto rounded-lg text-center "
            layoutId={selectedId}
          >
            <motion.h1>GRACIAS POR COMPLETAR LA ENCUESTA</motion.h1>
            <motion.p className="text-lg">
              Pronto anunciaremos los artistas{" "}
            </motion.p>

            <motion.button className="px-5 border rounded  mt-5"    onClick={() => {
            setAlert(false);
            setSelectedId(null);
          }}>
              Aceptar
            </motion.button>
          </motion.div>
        </motion.div>
      )}
      {selectedId && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
            className=" p-5 fixed z-50 w-full h-screen  inset-0  m-auto bg-black/50 "
          ></motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.15 } }}
            transition={{ duration: 0.2, delay: 0.15 }}
            style={{ pointerEvents: "auto", backdropFilter: "blur(5px)" }}
            className="bg-white/40  ligthNeon shadow-lg  p-2 fixed z-[60] inset-x-0 inset-y-0 top-0 lg:inset-y-auto lg:top-0  neon     w-full lg:w-[500px] min-h-[200px]     m-auto rounded-lg  "
            layoutId={selectedId}
          >
            <div className="w-full flex justify-end px-2 ">
              <motion.button
                className="font-bold text-xl"
                onClick={() => setSelectedId(null)}
              >
                {" "}
                X
              </motion.button>
            </div>
            <motion.div className="px-4 text-center w-full">
              <motion.h5 className=" font-medium text-white">
                Solicita tus 2 artistas favoritos que desees que asistan al
                evento{" "}
              </motion.h5>

              <motion.form className="mt-10 text-start w-full flex flex-col gap-y-5 mb-10 text-white">
                <motion.div>
                  <motion.label className="block font-medium">
                    Primer Artista
                  </motion.label>
                  <motion.input
                    value={data.firstArtis}
                    onChange={(e) =>
                      setData({ ...data, firstArtis: e.target.value })
                    }
                    className="w-full py-2 px-4 rounded-lg bg-white/20  placeholder:text-white/60 text-white"
                    placeholder="Ingrese el nombre del artista"
                  ></motion.input>
                </motion.div>
                <motion.div>
                  <motion.label className="block font-medium">
                    Segundo Artista
                  </motion.label>
                  <motion.input
                    value={data.secondArtis}
                    onChange={(e) =>
                      setData({ ...data, secondArtis: e.target.value })
                    }
                    className="w-full py-2 px-4 rounded-lg bg-white/20 placeholder:text-white/60 text-white"
                    placeholder="Ingrese el nombre del artista"
                  ></motion.input>
                </motion.div>

                <hr />
                <h5 className="text-xl font-bold">Datos opcionales</h5>
                <motion.div>
                  <motion.label className="block font-medium">
                    Nombre y Apellido
                  </motion.label>
                  <motion.input
                    value={data.user}
                    onChange={(e) => setData({ ...data, user: e.target.value })}
                    className="w-full py-2 px-4 rounded-lg bg-white/20 placeholder:text-white/60 text-white"
                    placeholder="Ingrese tu nombre y apellido"
                  ></motion.input>
                </motion.div>
                <motion.div>
                  <motion.label className="block font-medium">
                    Correo
                  </motion.label>
                  <motion.input
                    value={data.email}
                    onChange={(e) =>
                      setData({ ...data, email: e.target.value })
                    }
                    className="w-full py-2 px-4 rounded-lg bg-white/20 placeholder:text-white/60 text-white"
                    placeholder="Ingrese tu correo"
                  ></motion.input>
                </motion.div>
              </motion.form>
              <motion.button
                className="neonText neons text-white w-full rounded-lg mb-5"
                disabled={loading === true}
                onClick={completePoll}
              >
                {loading ? "Cargando.." : "Enviar"}
              </motion.button>
            </motion.div>
          </motion.div>
        </>
      )}
    </div>
  );
}

export default ModalSolicitud;
