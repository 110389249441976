import "./App.css";
import {  useState } from "react";
import BackgroundFixed from "./Components/Background/Background";
import Header from "./Components/Header/Header";

import Artist from "./pages/Artist/Artist";
import HeroHome from "./pages/HeroHome/HeroHome";
import FooterContent from "./Components/Footer/Footer";

function App() {
  const [artisId, setArtisId] = useState(null);


  return (
    


    <div className="bg-black w-full h-screen relative">
      <BackgroundFixed />

      <Header />
      <HeroHome />
      <Artist setArtisId={setArtisId} />
      <FooterContent/>
      <div className="fixed z-40 bottom-0 right-0 w-full">
        {artisId&&<button className="absolute z-30 font-bold w-9 h-9 text-center right-10 -top-4  text-xl  bg-white  text-purple-700 rounded-full" onClick={()=>setArtisId(null)}>X</button>}
        {
          // eslint-disable-next-line jsx-a11y/iframe-has-title
          <iframe
            id="music"
            className={`opacity-70 rounded-t-xl transition-all duration-200 ${
              artisId ? "translate-x-0" : "translate-y-[100%]"
            } `}
            src={`https://open.spotify.com/embed/artist/${artisId}?utm_source=generator`}
            width="100%"
            height="80"
            frameBorder="0"
            allowfullscreen="true"
            allowtransparency="true"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        }
      </div>
      

    </div>
  );
}

export default App;
