import React, { useEffect, useState } from "react";
import anuel from "../../assets/img/anuel.jpg";
import jowelyRandy from "../../assets/img/jowellyrandy.jpg";
import mora from "../../assets/img/mora.jpg";
import justinQuiles from "../../assets/img/justinQuiles.png";
import heroBackground from "../../assets/img/webFondo.jpg";
import corinaSmith from "../../assets/img/corinaSmith.jpg";
import danielaBarranco from "../../assets/img/danielaBarranco.jpg";
import gustavoElis from "../../assets/img/gustavoelis.jpg";
import melodiaPerfecta from "../../assets/img/lamelodiaperfecta.jpg";
import aranOne from "../../assets/img/aranone.jpg";
import santiPrince from "../../assets/img/santiprince.jpg";

import {  AnimatePresence, motion } from "framer-motion";
import ModalSolicitud from "./ModalSolicitud";
function Artist({ setArtisId }) {
  const [selectedId, setSelectedId] = useState(null);

  const itemsArtist = [
    {
      img: anuel,
      title: "ANUEL AA",
      desc: "xxxxxx",
      confirm: true,
      id: "2R21vXR83lH98kGeO99Y66",
    },
    {
      img: jowelyRandy,
      title: "JOWELL Y RANDY",
      desc: "xxxxxxx",
      confirm: true,
      id: "4IMAo2UQchVFyPH24PAjUs",
    },
    {
      img: mora,
      title: "MORA",
      confirm: false,
      desc: "xxxxxx",
      id: "0Q8NcsJwoCbZOHHW63su5S",
    },
    {
      img: justinQuiles,
      title: "JUSTIN QUILES",
      desc: "xxxxxx ",
      confirm: false,
      id: "14zUHaJZo1mnYtn6IBRaRP",
    }
  ];

  const itemsOtherArtist = [
    {
      img: corinaSmith,
      title: "CORINA SMITH",
      desc: "xxxxxx",
      confirm: true,

      id: "7mXfsy3lF4kU0f2KTNKSr8",
    },
    {
      img: danielaBarranco,
      title: "DANIELA BARRANCO",
      confirm: true,

      desc: "xxxxxxx",
      id: "39iUw6py1oGdVZ8TMWepc6",
    },
    {
      img: gustavoElis,
      title: "GUSTAVO ELIS",
      confirm: true,

      desc: "xxxxxx",
      id: "2MmmFlTRNXxmrat4kdglT4",
    },
    {
      img: melodiaPerfecta,
      title: "LA MELODIA PERFECTA",
      confirm: true,
      desc: "xxxxxx ",
      id: "61dT5hbLLfOUSg7Rzfxxug",
    },
    {
      img: aranOne,
      confirm: true,

      title: "ARAN ONE",
      desc: "xxxxxxx",
      id: "2J6w7AO0a1hghIqa6cO7pg",
    },
    {
      img: santiPrince,
      confirm: true,

      title: "SANTI PRINCE",
      desc: "xxxxxxx",
      id: "0cYjv2hfsAYFQnu2rOse0S",
    },
    {
      img: santiPrince,
      confirm: false,

      title: "SANTI PRINCE",
      desc: "xxxxxxx",
      id: "2J6w7AO0a1hghIqa6cO7pgzxcda",
    },
    {
      img: santiPrince,
      confirm: false,

      title: "SANTI PRINCE",
      desc: "xxxxxxx",
      id: "2J6w7AO0a1hghIqa6cO7pg23",
    },
    {
      img: santiPrince,
      confirm: false,

      title: "SANTI PRINCE",
      desc: "xxxxxxx",
      id: "2J6w7AO0a1hghIqa6cO7pgas",
    },
    {
      img: santiPrince,
      confirm: false,

      title: "SANTI PRINCE",
      desc: "xxxxxxx",
      id: "2J6w7AO0a1hghIqa6cO7pgss",
    },
  ];
  const [top, setTop] = useState(true);
  const [scrollValue, setScrollValue] = useState(null);
  console.log(scrollValue);
  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    if (!scrollValue) {
      setScrollValue(
        document.getElementById("titles").getBoundingClientRect()?.top
      );
    }
    const scrollHandler = () => {
      const scroll = document.getElementById("titles");
      const yPos = scroll.getBoundingClientRect()?.top;
      if (yPos - 50 <= window.pageYOffset) {
        setTop(false);
      }

      if (window.pageYOffset < scrollValue) {
        setTop(true);
      }
    };
    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [top, scrollValue]);

  return (
    <>
<AnimatePresence>
    <ModalSolicitud setSelectedId={setSelectedId} selectedId={selectedId} />
          </AnimatePresence>
   
      <section className="w-full h-auto relative z-30 max-w-6xl mx-auto px-4 sm:px-6    ">
        <div className="h-screen    h-screeb flex items-center justify-center">
          <motion.h1
            className={` text-white font-bold text-center neonText mt z-50 ${
              !top ? "fixed top-20 text-5xl" : "text-6xl"
            }`}
            initial={{ opacity: 0, scale: 0 }}
            layout
            id="titles"
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
            }}
          >
            Artistas Invitados
          </motion.h1>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-12 justify-center items-center w-full mx-auto">
          {itemsArtist.map((el, key) => {
            return (
              <motion.div
                key={key}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                className={el?.otherClass ?? ""}
                layoutId={el.id}
                onClick={() => (el.confirm ? null : setSelectedId(el.id))}
              >
                <div
                  className={
                    "   h-[500px] lg:h-screen  w-auto flex flex-col items-center text-center "
                  }
                >
                  <motion.div
                    initial={{ opacity: 0, y: 100, rotate: 0 }}
                    whileInView={{ opacity: 1, y: 0, rotate: -10 }}
                  >
                    {el.confirm ? (
                      <img
                        src={el.img}
                        alt={el.title}
                        onClick={() => setArtisId(el.id)}
                        className="object-cover object-top	   w-[200px] h-[200px] lg:w-[300px] lg:h-[400px] rounded-xl cursor-pointer ligthNeon"
                      />
                    ) : (
                      <div
                        className="relative flex flex-col items-center   w-[200px] justify-center border  h-[200px] lg:w-[300px] lg:h-[400px] rounded-xl cursor-pointer ligthNeon overflow-hidden "
                        style={{
                          backgroundImage: `url(${heroBackground})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      >
                        <h1 className="text-6xl font-bold text-white">?</h1>
                        <button className="text-4xl font-bold neonText       rounded-xl  px-2">
                          Solicitar Artista
                        </button>
                      </div>
                    )}
                  </motion.div>
                  <motion.h1
                    className="text-6xl text-white font-bold neonText"
                    initial={{ opacity: 0, y: 100, rotate: 0 }}
                    whileInView={{ opacity: 1, y: 0, rotate: -10 }}
                  >
                    {el.confirm ? el.title : ""}
                  </motion.h1>
                </div>
              </motion.div>
            );
          })}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-0 lg:gap-12 justify-center items-center w-full mx-auto">
          {itemsOtherArtist.map((el, key) => {
            return (
              <motion.div
                key={key}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                className={el?.otherClass ?? ""}
                     layoutId={el.id}
                onClick={() => (el.confirm ? null : setSelectedId(el.id))}
              >
                <div
                  className={
                    "  h-[500px] lg:h-screen  w-auto flex flex-col items-center text-center "
                  }
                >
                  <motion.div
                    initial={{ opacity: 0, y: 100, rotate: 0 }}
                    whileInView={{ opacity: 1, y: 0, rotate: -10 }}
                  >
                   {el.confirm ? (
                      <img
                        src={el.img}
                        alt={el.title}
                        onClick={() => setArtisId(el.id)}
                        className="object-cover object-top	   w-[200px] h-[200px] lg:w-[300px] lg:h-[400px] rounded-xl cursor-pointer ligthNeon"
                      />
                    ) : (
                      <div
                        className="relative flex flex-col items-center  w-[200px] justify-center border  h-[200px] lg:w-[300px] lg:h-[400px] rounded-xl cursor-pointer ligthNeon overflow-hidden "
                        style={{
                          backgroundImage: `url(${heroBackground})`,
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      >
                        <h1 className="text-6xl font-bold text-white">?</h1>
                        <button className="text-4xl font-bold neonText   rounded-xl  px-2">
                          Solicitar Artista
                        </button>
                      </div>
                    )}
                  </motion.div>
                  <motion.h1
                    className="text-6xl text-white font-bold neonText"
                    initial={{ opacity: 0, y: 100, rotate: 0 }}
                    whileInView={{ opacity: 1, y: 0, rotate: -10 }}
                  >
                    {el.confirm ? el.title : ""}
                  </motion.h1>
                </div>
              </motion.div>
            );
          })}
        </div>
      </section>
    </>
  );
}

export default Artist;

/*       data-aos="zoom-y-out"
              data-aos-delay="300" */
