import React from "react";
import logo from "../../assets/img/logoMusikFest.png";
import Particles from "react-particles";
import { loadStarsPreset } from "tsparticles-preset-stars";
import { motion } from "framer-motion";
function HeroHome() {
  async function customInit(engine) {
    // this adds the preset to tsParticles, you can safely use the
    await loadStarsPreset(engine);
  }

  const steps = [
    { title: "1ra Etapa", status: true },
    { title: "2da Etapa", status: false },
    { title: "3ra Etapa", status: false },
  ];

  return (
    <div className="relative w-full h-full">
      <div className=" flex flex-col justify-center gap-16 items-center h-auto lg:h-full relative overflow-hidden text-center py-[10rem] lg:py-[0]">
        <div>
          <img
            src={logo}
            alt="musikFest "
            className=" z-30 w-[300px] lg:w-[500px] bg-fixed ligthNeonImage	"
          />
        </div>
        <div>
          <h1 className="text-5xl text-white font-bold neonText">
            18 DE MARZO 2023{" "}
          </h1>
        </div>
        <div className="flex flex-col lg:flex-row gap-5 justify-center items-center ">
          {steps.map((el, key) => {
            return (
              <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
                transition={{ ease: "easeOut", duration: 2 }}
              >
                <div className="flex justify-center  items-center mx-5">
                  {el.status ? (
                    <a
                      key={"anuel"}
                      href="https://globalboletos.com/Event/MUSIKFEST"
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`${
                        el.status
                          ? "neons"
                          : " hover:bg-[#ff00ff]/50 text-white/50 transition-all duration-200  "
                      } font-bold p-4 py-2 border text-[25px]  border-[#ff00ff] rounded-full flex flex-col text-white`}
                    >
                      {el.title}
                      <span className="text-sm">
                        {el.status ? "Comprar" : "No disponible"}
                      </span>
                    </a>
                  ) : (
                    <p
                      className={`${
                        el.status
                          ? "neons"
                          : " hover:bg-[#ff00ff]/50 text-white/50 transition-all duration-200  "
                      } font-bold p-5   py-1 border text-[20px]  border-[#ff00ff] rounded-full flex flex-col cursor-not-allowed   `}
                    >
                      {el.title}
                      <span className="text-sm">
                        {el.status ? "Comprar" : "No disponible"}
                      </span>
                    </p>
                  )}
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>
      <Particles
        options={{
          preset: "stars",
          background: {
            color: "transparent",
          },
        }}
        init={customInit}
      />
    </div>
  );
}

export default HeroHome;
