import React from 'react'


function FooterContent() {

    return ( <footer className='w-full  bg-black h-auto  text-center '>
        <div className='absolute bg-black text-white w-full  p-2'>
        <p>Todos los derechos son reservados </p>
        </div>

    </footer> );
}

export default FooterContent;