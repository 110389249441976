import React,{useState,useEffect} from 'react'
import logo from '../../assets/img/logoMusikFest.png'
import { motion } from "framer-motion";

function Header() {
    const [top, setTop] = useState(true);
    

    // detect whether user has scrolled the page down by 10px 
    useEffect(() => {
      const scrollHandler = () => {
        window.pageYOffset > 100 ? setTop(false) : setTop(true)
      
      };
      window.addEventListener('scroll', scrollHandler);
      return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);  
  
    return ( <>
    <header className={` p-3 w-full fixed top-0 z-40 flex transition-all ease-out bg-clip-padding bg-opacity-60 `} style={{backdropFilter:!top&&"blur(20px)"}}>
       <motion.div  animate={!top? { x: [0, 130, 130] }:{ x: [130, 0, 0] }}  >

        <img src={logo} alt="" className={`w-20 relative z-40 transition-all ease-in transform duration-150 ligthNeonImage ${!top?"mx-auto":"m-0"}`} />
       </motion.div>
    </header>
    </> );
}

export default Header;