import React, { useEffect, useState } from 'react'
import background from "../../assets/img/webFondo.jpg";

function BackgroundFixed() {

    const [top, setTop] = useState(true);
    const [top2, setTop2] = useState(true);

    // detect whether user has scrolled the page down by 10px 
    useEffect(() => {
      const scrollHandler = () => {
        window.pageYOffset > 350 ? setTop(false) : setTop(true)
        window.pageYOffset > 450 ? setTop2(false) : setTop2(true)
      };
      window.addEventListener('scroll', scrollHandler);
      return () => window.removeEventListener('scroll', scrollHandler);
    }, [top]);  
  

    return ( <>
          <section
        className={`fixed w-full h-full  transition-all duration-700      ${!top?"blur":!top2&&"blur-lg"}`}
        id="fondoStyle"
        style={{
          backgroundImage: `url(${background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          
        }}
      >
      </section>
    </> );
}

export default BackgroundFixed;